<template>
  <div></div>
</template>

<script>

import {mapGetters, mapActions} from "vuex";

export default {
  name: "Callback",

  /************************************************************************************
   *                                    COMPONENTS
   * **********************************************************************************/
  components: {

  },

  /************************************************************************************
   *                                    DATA
   * **********************************************************************************/
  data() {
    return {

    }
  },

  /************************************************************************************
   *                                    COMPUTED
   * **********************************************************************************/
  computed: {
    ...mapGetters(["currentUserType"]),
  },

  /************************************************************************************
   *                                    ACTIONS
   * **********************************************************************************/
  actions: {

  },

  /************************************************************************************
   *                                    METHODS
   * **********************************************************************************/
  methods: {
    ...mapActions(["initSession"]),

    clearForm() {
      const self = this;
      self.loginFormData = {};
    },
  },

  /************************************************************************************
   *                                    WATCH
   * **********************************************************************************/
  watch: {

  },

  /************************************************************************************
   *                                    CREATED
   * **********************************************************************************/
  created() {

  },

  /************************************************************************************
   *                                  MOUNTED
   * **********************************************************************************/
  mounted() {
    const code = this.$route.query.code;

    if (!code) {
      self.$router.push("/signin");
    } else {
      const state = localStorage.getItem('state');
      const codeVerifier = localStorage.getItem('codeVerifier');
      const urlState = this.$route.query.state;
      const self = this;

      if (state.length <= 0 || state !== urlState) {
        self.$swal.fire({
          timer: 2000,
          icon: "error",
          title: self.$t("dialogs.titles.error"),
          text: self.$t("auth.errors.oauthError"),
        });

        return;
      }

      const data = {
        codeVerifier: codeVerifier,
        code: code
      }

      self.initSession(data)
          .then((suss) =>{
            if (!suss.access_token && !self.isLoggedIn) return;

            if (self.currentUserType.key === 'master_admin') {
              self.$router.push('/tenant/menu').finally(() =>{
                self.clearForm();
              });

              return;
            }

            self.$router.push({name: "Home"}).finally(() =>{
              self.clearForm();
            });
          }).catch((err) => {
            if (err.status == 401) {
              self.$swal.fire({
                timer: 2000,
                icon: "error",
                title: self.$t("dialogs.titles.error"),
                text: self.$t("auth.errors.codeOauthError"),
              });
            } else if (err.status == 500) {
              self.$swal.fire({
                timer: 2000,
                icon: "error",
                title: self.$t("dialogs.titles.error"),
                text: self.$t("dialogs.body.error"),
              });
            }
          });
    }
  },
}
</script>

<style scoped>

</style>